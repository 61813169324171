import { lazy } from "react";
import IllustrateFirstScreen from "../pages/authority/illustrateFirstScreen";
import GoalReminder from "../pages/authority/goalReminder";

const LoginComponent = lazy(() =>
  import("../pages/general").then(({ Login }) => ({ default: Login }))
);

const ObliterateComponent = lazy(() =>
  import("../pages/general").then(({ ObliteratePage }) => ({
    default: ObliteratePage,
  }))
);

const ResetComponent = lazy(() =>
  import("../pages/general").then(({ ResetPage }) => ({ default: ResetPage }))
);

const StructureComponent = lazy(() =>
  import("../pages/authority").then(({ Structure }) => ({ default: Structure }))
);

const UserComponent = lazy(() =>
  import("../pages/authority").then(({ User }) => ({ default: User }))
);

const CategoryComponent = lazy(() =>
  import("../pages/authority").then(({ CategoryPage }) => ({
    default: CategoryPage,
  }))
);

const IllustrationComponent = lazy(() =>
  import("../pages/authority").then(({ Illustration }) => ({
    default: Illustration,
  }))
);

const IllustrateCheckInComponent = lazy(() =>
  import("../pages/authority").then(({ IllustrateCheckIn }) => ({
    default: IllustrateCheckIn,
  }))
);

const IllustrateSosComponent = lazy(() =>
  import("../pages/authority").then(({ IllustrateSos }) => ({
    default: IllustrateSos,
  }))
);

const QuoteComponent = lazy(() =>
  import("../pages/authority").then(({ Quote }) => ({
    default: Quote,
  }))
);

const SubscriptionComponent = lazy(() =>
  import("../pages/authority").then(({ Susbscription }) => ({
    default: Susbscription,
  }))
);

export interface RouteConfigDeclaration {
  /**
   * 当前路由路径
   */
  path: string;
  /**
   * 当前路由名称
   */
  name?: string;
  /**
   * 是否严格匹配路由
   */
  exact?: boolean;
  /**
   * 是否需要路由鉴权
   */
  auth?: boolean;
  /**
   * 是否需要路由重定向
   */
  isRedirect?: boolean;
  /**
   * 是否需要动态加载路由
   */
  isDynamic?: boolean;
  /**
   * 动态加载路由时的提示文案
   */
  loadingFallback?: string;
  /**
   * 路由组件
   */
  element: any;
  /**
   * 子路由
   */
  children?: RouteConfigDeclaration[];
}
const routes: RouteConfigDeclaration[] = [
  {
    path: "/login",
    element: LoginComponent,
    exact: true,
    auth: false,
  },
  {
    path: "/obliterate",
    element: ObliterateComponent,
    exact: true,
    auth: false,
  },
  {
    path: "/reset",
    element: ResetComponent,
    exact: true,
    auth: false,
  },
  {
    path: "/*",
    element: StructureComponent,
    exact: false,
    auth: false,
    children: [
      {
        path: "/user",
        element: UserComponent,
        exact: false,
      },
      {
        path: "/category",
        element: CategoryComponent,
        exact: false,
      },
      {
        path: "/illustration/*",
        element: IllustrationComponent,
        exact: false,
        children: [
          {
            path: "check",
            element: IllustrateCheckInComponent,
            exact: true,
          },
          {
            path: "sos",
            element: IllustrateSosComponent,
            exact: true,
          },
          {
            path: "firstScreen",
            element: IllustrateFirstScreen,
            exact: true,
          },
        ],
      },
      {
        path: "/quote",
        element: QuoteComponent,
        exact: false,
      },
      {
        path: "/goalReminder",
        element: GoalReminder,
        exact: false,
      },
      {
        path: "/subscription",
        element: SubscriptionComponent,
        exact: false,
      },
    ],
  },
];
export default routes;
