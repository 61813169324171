import DialogHook from "./dialogHook";
import "./dialog.scss";

const DialogCuztomized = (props: any) => {
  const { Modal } = DialogHook(props);

  return (
    <div
      style={{ width: `${props.width}px`, opacity: props.opacityZero ? 0 : 1 }}
      className="dialog-wrp"
    >
      <div className="tit-area clear">
        {props.title}
        <div
          className="close"
          onClick={() => {
            if (props.forbiddenClose) return;
            props.emitEvt("close");
          }}
        ></div>
      </div>
      {props.slot}
    </div>
  );
};

export { DialogCuztomized };
