import { useState, useEffect } from "react";
import "./choose.scss";
import ChooseHook from "./chooseHook";

const Choose = (props: any) => {
  const { Select, Option, value, selectChange } = ChooseHook(props);
  return (
    <Select value={value} className="choose-customize" onChange={selectChange}>
      {props.options.map((item: any) => {
        return (
          <Option key={item.value} value={item.value}>
            {item.label}
          </Option>
        );
      })}
    </Select>
  );
};

export { Choose };
