import "./table.scss";
import TableHook from "./tableHook";
import { Spin } from "antd";

const Table = (props: any) => {
  const { LoadingOutlined, sort } = TableHook(props);

  return (
    <div className="grid-wrp">
      <div className={`grid-head ${props.class}`}>
        {props.headList.map((item: any, index: number) => {
          return (
            <div key={item.id} className="label">
              <div>
                <span>{item.label}</span>
                {item.isSort ? (
                  <span
                    style={{
                      opacity: props.overRule ? "0.6" : "1",
                    }}
                    className={`arrow ${props.currentSort.type}`}
                    onClick={() => {
                      if (props.overRule) {
                        props.setOverRule(false);
                        sort(item.label, "descend", false);
                      } else if (item.sortDirection === "descend") {
                        sort(item.label, "ascend", false);
                      } else if (item.sortDirection === "ascend") {
                        props.setOverRule(true);
                        sort(item.label, "descend", true);
                      }
                      /*sort(
                        item.label,
                        item.sortDirection === "descend" ? "ascend" : "descend"
                      );*/
                    }}
                  ></span>
                ) : (
                  ""
                )}
              </div>
            </div>
          );
        })}
      </div>

      <div className="grid-body scrollable">
        <Spin
          spinning={props.loading}
          indicator={<LoadingOutlined />}
          wrapperClassName="grid-body-loading"
        >
          {!props.loading && props.total === 0 ? (
            <div className="grid-empty">
              {props.initTotal !== 0 && props.keyword && props.total === 0 ? (
                <>
                  Sorry, we were unable to find any results based on your
                  conditions.
                </>
              ) : (
                <>No Data</>
              )}
            </div>
          ) : (
            ""
          )}
          {/*props.loading ? (
            <div className="grid-empty">
              <LoadingOutlined />
            </div>
          ) : (
            ""
          )*/}
          {props.total !== 0 ? <>{props.slot}</> : ""}
        </Spin>
      </div>
    </div>
  );
};

export { Table };
