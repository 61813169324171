import axios, { AxiosError, AxiosResponse } from "axios";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { useDispatch, useSelector } from "react-redux";
import { validateToken } from "../app/authority";

const instance = axios.create({
  baseURL: process.env.REACT_APP_URL,
  timeout: 10000,
});
instance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = localStorage.getItem("ogaat-admin-satoken");
    if (token) {
      (config.headers as AxiosRequestHeaders)["ogaat-admin-satoken"] = token;
    }
    (config.headers as AxiosRequestHeaders)["Accept-Language"] = "en-US";
    return config;
  },
  (error: any) => {
    Promise.reject(error);
  }
);

const accountErrorCodes = [5000, 5001, 5002, 5003, 5004];

const AxiosInterceptor = ({ children }: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const resInterceptor = (response: any) => {
    const code = response.data.code;
    if (accountErrorCodes.includes(code)) {
      sessionStorage.clear();
      localStorage.clear();
      navigate("/login");
      dispatch(validateToken("fail"));
    }

    if (code !== 200) {
      throw new Error(response.data.message);
    }
    return response;
  };

  const errInterceptor = (error: any) => {
    return Promise.reject(error);
  };

  useEffect(() => {
    const interceptor = instance.interceptors.response.use(
      resInterceptor,
      errInterceptor
    );
    return () => instance.interceptors.response.eject(interceptor);
  }, []);

  // useEffect(() => {
  //   let location = window.location.href;
  //   if (
  //     location.indexOf("/login") === -1 &&
  //     location.indexOf("/reset") === -1 &&
  //     status.logout === 1
  //   ) {
  //     navigate("/login");
  //   }
  // }, [status.logout]);

  return children;
};

export default instance;
export { AxiosInterceptor };
