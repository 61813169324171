import UploadHook from "./uploadHook";
import "./upload.scss";
import "cropperjs/dist/cropper.css";

const UploadCuztomized = (props: any) => {
  const {
    pictureStage,
    Upload,
    Modal,
    open,
    originalBase64,
    Button,
    LoadingOutlined,
    opacity,
    onChange,
    beforeUpload,
    uploadCropImg,
    closeUpload,
    delIco,
  } = UploadHook(props);

  return (
    <div
      className={props.rectangular ? "upload-wrp rectangular" : "upload-wrp"}
    >
      {!pictureStage.uploading && pictureStage.url === "" ? (
        <Upload
          listType="picture-card"
          className="ico-uploader"
          customRequest={onChange}
          showUploadList={false}
          beforeUpload={beforeUpload}
          accept="image/png, image/jpeg"
        >
          <div className="ico"></div>
        </Upload>
      ) : (
        ""
      )}
      {pictureStage.uploading ? (
        <div className="ico-uploading">
          <LoadingOutlined />
        </div>
      ) : (
        ""
      )}
      {!pictureStage.uploading && pictureStage.url !== "" ? (
        <div className="ico-uplaoded">
          <div
            className="img"
            style={{
              backgroundImage: `url(${
                pictureStage.base64 ? pictureStage.base64 : pictureStage.url
              })`,
            }}
          ></div>
          <div
            className="del-ico"
            onClick={() => {
              delIco();
            }}
          ></div>
        </div>
      ) : (
        ""
      )}

      <Modal
        className="complicated-wrp"
        open={open}
        maskClosable={false}
        closable={false}
        centered={true}
        destroyOnClose
        width={500}
      >
        <div className="cropper_box" style={{ opacity: opacity }}>
          <img id={props.id} src={originalBase64} />
        </div>

        <div className="cropper-btns">
          <Button
            className="cancel-btn"
            onClick={() => {
              closeUpload();
            }}
          >
            Cancel
          </Button>
          <Button
            className="confirm-btn"
            onClick={() => {
              uploadCropImg();
            }}
          >
            Confirm
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export { UploadCuztomized };
