import { useState, useEffect } from "react";
import { Pagination } from "antd";

const PageJumpHook = (props: any) => {
  const [page, setPage] = useState(props.current);
  const [total, setTotal] = useState(props.total);
  const pageChange = (p: number) => {
    setPage(p);
    props.emitPageChange(p);
  };
  useEffect(() => {
    setTotal(props.total);
  }, [props.total]);

  useEffect(() => {
    if (props.current !== page) setPage(props.current);
  }, [props.current]);
  return {
    Pagination,
    page,
    total,
    pageChange,
  };
};
export default PageJumpHook;
