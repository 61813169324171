import "./pageJump.scss";
import PageJumpHook from "./pageJumpHook";

const PageJump = (props: any) => {
  const { Pagination, page, total, pageChange } = PageJumpHook(props);

  return (
    <div className="jump-wrp clear">
      <div
        className={page * 10 >= total ? "last-button disable" : "last-button"}
        onClick={() => {
          pageChange(Math.ceil(total / 10));
        }}
      >
        Last
      </div>

      <Pagination
        defaultCurrent={page}
        defaultPageSize={10}
        current={page}
        total={total}
        showSizeChanger={false}
        onChange={(e) => {
          pageChange(e);
        }}
      />

      <div
        className={page === 1 ? "first-button disable" : "first-button"}
        onClick={() => {
          pageChange(1);
        }}
      >
        First
      </div>
    </div>
  );
};

export { PageJump };
