import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  login: false,
  logout: 0,
  level: 1,
  currentChangingEmail: "",
  currentChangingCode: "",
};

const authSlice = createSlice({
  name: "authority",
  initialState,
  reducers: {
    validateToken(state, action) {
      if (action.payload.result === "success") {
        state.login = true;
      } else {
        state.login = false;
        if (action.payload.result === "fail") state.logout = 1;
      }
    },
    changeCurrent(state, action) {
      if (action.payload.result === "store") {
        state.currentChangingEmail = action.payload.email;
        state.currentChangingCode = action.payload.code;
      } else {
        state.currentChangingEmail = "";
        state.currentChangingCode = "";
      }
    },
  },
});

export const { validateToken, changeCurrent } = authSlice.actions;

export default authSlice.reducer;
