import { Select } from "antd";
import { useState } from "react";
const { Option } = Select;

const ChooseHook = (props: any) => {
  const [value, setValue] = useState(props.value);
  const selectChange = (e: string) => {
    setValue(e);
    props.choseChange(e);
  };

  return { Select, Option, value, selectChange };
};
export default ChooseHook;
