import { LoadingOutlined } from "@ant-design/icons";

const TableHook = (props: any) => {
  const sort = (sort: string, type: string, over: boolean) => {
    props.sortChange(sort, type, over);
  };
  return {
    LoadingOutlined,
    sort,
  };
};
export default TableHook;
