import SimpleDialogHook from "./simpleDialogHook";
import "./simpleDialog.scss";

const SimpleDialogCuztomized = (props: any) => {
  const { Modal, Button, LoadingOutlined } = SimpleDialogHook(props);

  return (
    <Modal
      className="simple-wrp"
      open={props.data.show}
      maskClosable={false}
      closable={false}
      centered={true}
      width={props.width}
    >
      <div className="tit-area">{props.data.title}</div>
      <div className="btns">
        <Button
          className="cancel-btn"
          disabled={props.data.loading}
          onMouseDown={() => {
            props.emitEvent("cancel");
          }}
        >
          Cancel
        </Button>
        <Button
          className="confirm-btn"
          disabled={props.data.loading}
          onMouseDown={() => {
            props.emitEvent("confirm");
          }}
        >
          {props.cuztomizedConfirmText
            ? props.cuztomizedConfirmText
            : "Confirm"}{" "}
          {props.data.loading ? <LoadingOutlined /> : ""}
        </Button>
      </div>
    </Modal>
  );
};

export { SimpleDialogCuztomized };
