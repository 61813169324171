import ComplicatedDialogHook from "./complicatedDialogHook";
import "./complicatedDialog.scss";

const ComplicatedDialogCuztomized = (props: any) => {
  const { Modal, LoadingOutlined } = ComplicatedDialogHook(props);

  return (
    <Modal
      className="complicated-wrp"
      open={props.data.show}
      maskClosable={false}
      closable={false}
      centered={true}
      width={props.width}
    >
      <div className="tit-area clear">
        {props.data.title}
        <div
          className="close"
          onMouseDown={() => {
            props.emitEvent("cancel");
          }}
        ></div>
      </div>
      {props.slot}
    </Modal>
  );
};

export { ComplicatedDialogCuztomized };
