import { Button, Form, Input, message, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { UploadCuztomized } from "../../../sharedModule";
import api from "@/api/firstScreen";

const CreateNewModal = ({
  visible,
  type,
  editItem,
  onClose,
}: {
  visible: boolean;
  type: string;
  editItem?: any;
  onClose: () => void;
}) => {
  const [saving, setSaving] = useState(false);

  const [formRef] = Form.useForm();
  const imageValues = Form.useWatch("illustration", formRef);
  const titleValues = Form.useWatch("title", formRef);

  const disabled = !imageValues || !titleValues || saving;

  const [warning, setWarning] = useState(false);

  const onFinish = (values: any) => {
    if (imageValues === "") {
      return setWarning(true);
    }
    handleSave();
  };

  const handleClose = () => {
    formRef.resetFields();
    onClose();
  };

  const handleSave = async () => {
    const data = {
      ...editItem,
      title: titleValues,
      illustration: imageValues,
    };
    try {
      setSaving(true);
      if (type === "Create") {
        await api.createFirstScreen({ data });
      } else {
        await api.editFirstScreen({ data });
      }
      handleClose();
    } catch (error: any) {
      message.error(error.message);
    } finally {
      setSaving(false);
    }
  };

  const handleUploadImage = (type: string, u: string) => {
    formRef.setFieldsValue({ illustration: u });
    setWarning(false);
  };

  useEffect(() => {
    if (!editItem) return;
    formRef.setFieldsValue({ ...editItem });
  }, [editItem, formRef]);

  return (
    <Modal className="complicated-wrp" open={visible} width={720}>
      <div className="tit-area clear">
        {type}
        <div className="close" onClick={handleClose}></div>
      </div>

      <Form
        className="normal-form"
        form={formRef}
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Illustration"
          name="types1"
          initialValue={"First Screen"}
          validateTrigger={["onBlur"]}
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
        >
          <Input value="First Screen" disabled={true} />
        </Form.Item>

        <Form.Item
          label="Title"
          name="title"
          validateTrigger={["onBlur"]}
          rules={[
            {
              required: true,
              message: "Please enter the title",
            },
          ]}
        >
          <Input placeholder="" maxLength={50} showCount={true} />
        </Form.Item>

        <Form.Item
          label="Background"
          name="illustration"
          className="first-screen-image-upload"
          rules={[
            {
              required: true,
              message: "Please upload an image",
            },
          ]}
        >
          <>
            <div className="form-warn">
              Please upload an image in png or jpg or jpeg format
            </div>
            <div className="clear image-wrp">
              <UploadCuztomized
                url={editItem.illustration || ""}
                id="image1"
                icoChange={handleUploadImage}
                type="image"
                rectangular={true}
                aspectRatio={375 / 812}
              />
            </div>
          </>
        </Form.Item>
        <Button
          className={`save-button ${!disabled && "lighten"}`}
          htmlType="submit"
          style={{ marginTop: "20px" }}
        >
          Save
          {saving ? <LoadingOutlined /> : ""}
        </Button>
      </Form>
    </Modal>
  );
};

export default CreateNewModal;
