import { Button, Form, Input, Modal } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import api from "../../../../api/reminder";

const CreateNewModal = ({
  visible,
  title,
  editItem,
  onClose,
}: {
  visible: boolean;
  title: string;
  editItem?: any;
  onClose: () => void;
}) => {
  const [saving, setSaving] = useState(false);

  const [formRef] = Form.useForm();
  const contentValue = Form.useWatch("goalReminder", formRef);
  const disabled = !contentValue || saving;

  const onFinish = (values: any) => {
    handleSave();
  };

  const handleSave = async () => {
    setSaving(true);
    const data = {
      ...editItem,
      goalReminder: contentValue,
    };
    try {
      if (title === "Create") {
        await api.createReminder({ data });
      } else {
        await api.editReminder({ data });
      }
      onClose();
    } catch (error) {
    } finally {
      setSaving(false);
    }
  };

  useEffect(() => {
    if (!editItem) return;
    formRef.setFieldsValue({ ...editItem });
  }, [editItem, formRef]);

  const titleText = useMemo(() => {
    return title === "Create" ? "Create new reminder" : "Edit";
  }, [title]);

  return (
    <Modal
      className="complicated-wrp"
      open={visible}
      width={720}
      destroyOnClose
    >
      <div className="tit-area clear">
        {titleText}
        <div className="close" onClick={onClose}></div>
      </div>

      <Form
        className="normal-form"
        form={formRef}
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Reminder content"
          name="goalReminder"
          validateTrigger={["onBlur"]}
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
        >
          <Input.TextArea maxLength={200} showCount />
        </Form.Item>
        <Button
          className={`save-button ${!disabled && "lighten"}`}
          style={{ marginTop: 6 }}
          htmlType="submit"
          disabled={disabled}
        >
          Save
          {saving ? <LoadingOutlined /> : ""}
        </Button>
      </Form>
    </Modal>
  );
};

export default CreateNewModal;
