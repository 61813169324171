import { useState, useEffect } from "react";
import { message, Upload, Modal, Button } from "antd";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";
import { LoadingOutlined } from "@ant-design/icons";
import api from "../../../api/general";
import axios from "axios";
const CancelToken = axios.CancelToken;
let source = CancelToken.source();

const UploadHook = (props: any) => {
  const [pictureStage, setPictureStage] = useState({
    uploading: false,
    base64: "",
    url: "",
  });

  useEffect(() => {
    if (props.url === pictureStage.url) return;
    setPictureStage({
      uploading: false,
      base64: "",
      url: props.url,
    });
  }, [props.url]);

  const [originalBase64, setOriginalBase64] = useState("");
  const onChange = (e: any) => {
    console.log(e);
    var reader = new FileReader();
    reader.readAsDataURL(e.file); //将文件读取为 DataURL,也就是base64编码
    reader.onload = function (ev: any) {
      //文件读取成功完成时触发
      var dataURL = ev.target.result; //获得文件读取成功后的DataURL,也就是base64编码
      // console.log(dataURL);
      setOriginalBase64(dataURL);
      setOpen(true);
    };
  };

  const beforeUpload = (file: any) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      message.error("Image must smaller than 10MB!");
    }
    return isJpgOrPng && isLt10M;
  };

  const delIco = () => {
    setPictureStage({
      uploading: false,
      base64: "",
      url: "",
    });
    props.icoChange(props.type, "");
  };
  /////////////////////////////////////////////////////////////////////////////////////////////////
  const [open, setOpen] = useState(false);
  const [cropper, setCropper] = useState(null as any);

  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    if (originalBase64) {
      setTimeout(() => {
        init();
      }, 500);
    } else {
      if (pictureStage.uploading) {
        source.cancel("Operationcanceled");
        source = CancelToken.source();
      }
    }
  }, [originalBase64]);

  const dataURLtoFile = (dataurl: any, filename: string) => {
    let arr = dataurl.split(",");
    let mime = arr[0].match(/:(.*?);/)[1];
    if (!filename) {
      filename = `${new Date().getTime()}.${mime.substr(
        mime.indexOf("/") + 1
      )}`;
    }
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const init = () => {
    const image = document.getElementById(props.id) as any;
    console.log(image);

    const cropper = new Cropper(image, {
      aspectRatio: props.aspectRatio || 1,
      viewMode: 0,
      guides: false, // 是否显示剪裁框内部网格线
      minCropBoxWidth: 50, // 最小剪裁框宽度
      minCropBoxHeight: 50, // 最小剪裁框高度
      cropBoxResizable: true,
      highlight: false,
      ready() {
        setOpacity(1);
      },
    }) as any;

    setCropper(cropper);
  };

  const uploadCropImg = () => {
    const herf = cropper.getCroppedCanvas().toDataURL("image/png");

    //file格式
    const blob = dataURLtoFile(herf, "");
    getResult(blob, herf);
  };

  const closeUpload = () => {
    setOriginalBase64("");
    setOpen(false);
    setOpacity(0);
    cropper.destroy();
  };

  const getResult = (e: any, base64: any) => {
    closeUpload();
    let param = new FormData();
    param.append("file", e);
    setPictureStage({
      uploading: true,
      base64,
      url: "",
    });
    // 开始请求
    api.uploadApi({
      params: param,
      callback: (res: any) => {
        if (!res) {
          setPictureStage({
            uploading: false,
            base64,
            url: "",
          });
          return;
        }
        if (res.data.code === 200) {
          setPictureStage({
            uploading: false,
            base64,
            url: base64,
          });
          props.icoChange(props.type, res.data.data);
        } else {
          setPictureStage({
            uploading: false,
            base64,
            url: "",
          });
          message.error(res.data.message);
        }
      },
    });
  };
  /////////////////////////////////////////////////////////////////////////////////////////////////
  return {
    pictureStage,
    Upload,
    Modal,
    open,
    originalBase64,
    Button,
    LoadingOutlined,
    opacity,
    onChange,
    beforeUpload,
    uploadCropImg,
    closeUpload,
    delIco,
  };
};
export default UploadHook;
