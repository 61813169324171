import axios from "../request/index";

const api = {
  async getReminderList({ params = {} as any }) {
    const res = await axios.get("/goal-reminder/queryPage", {
      params,
    });
    return res.data;
  },
  async createReminder({ data = {} as any }) {
    const res = await axios.post("/goal-reminder/create", data, {});
    return res.data;
  },
  async editReminder({ data = {} as any }) {
    const res = await axios.put("/goal-reminder/edit", data, {});
    return res.data;
  },
  async deleteReminder({ id = "" as string }) {
    const res = await axios.delete(`/goal-reminder/delete/${id}`);
    return res.data;
  },
};

export default api;
