import axios from "../request/index";

const api = {
  async getFirstScreenList({ params = {} as any }) {
    const res = await axios.get("/first-screen/queryPage", {
      params,
    });
    return res.data;
  },
  async createFirstScreen({ data = {} as any }) {
    const res = await axios.post("/first-screen/create", data, {});
    return res.data;
  },
  async editFirstScreen({ data = {} as any }) {
    const res = await axios.put("/first-screen/edit", data, {});
    return res.data;
  },
  async deleteFirstScreen({ id = "" as string }) {
    const res = await axios.delete(`/first-screen/delete/${id}`);
    return res.data;
  },
};

export default api;
