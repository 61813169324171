import React, { useEffect, useState } from "react";
import {
  LookFor,
  PageJump,
  SimpleDialogCuztomized,
  Table,
} from "../../sharedModule";
import { Button, Dropdown, message } from "antd";
import CreateNewModal from "./components/CreateNewModal";
import api from "@/api/firstScreen";

const headList = [
  { label: "Image", id: "1" },
  { label: "Title", id: "3" },
];

const IllustrateFirstScreen = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [reqParams, setReqParams] = useState<{
    pageNum: number;
    keyword: string;
    pageSize?: number;
  }>({ pageNum: 1, pageSize: 10, keyword: "" });

  const [data, setData] = useState<any[]>([]);

  const [modal, setModal] = useState({
    visible: false,
    type: "Create",
  });
  const [removeModal, setRemoveModal] = useState({
    visible: false,
    loading: false,
    id: "",
  });

  const [editItem, setEditItem] = useState<any>({
    firstScreenId: "",
    types: 3,
    title: "",
    illustration: "",
  });

  const handleSearch = (key: string) => {
    setReqParams({
      ...reqParams,
      keyword: key,
    });
  };

  const getData = async () => {
    setLoading(true);
    try {
      const res = await api.getFirstScreenList({ params: reqParams });
      const data = res.data.records.map((i: any) => ({ ...i, open: false }));
      setData(data);
      setTotal(Number(res.data.total));
    } catch (error: any) {
      message.error(error.message);
    }
    setLoading(false);
  };

  const handleOpenDropdown = (idx: number) => {
    const temp = data.map((item, index) => {
      if (index === idx) {
        item.open = !item.open;
      } else {
        item.open = false;
      }
      return item;
    });
    setData(temp);
  };

  const closeAllDropdown = () => {
    const temp = data.map((item) => {
      item.open = false;
      return item;
    });
    setData(temp);
  };

  const handleCreateNewOrEdit = (type: string, id: string) => {
    setModal({
      visible: true,
      type: type,
    });
    if (id) {
      const editItem = data.find((item) => item.firstScreenId === id);
      setEditItem(editItem);
    } else {
      setEditItem({
        firstScreenId: "",
        types: 3,
        title: "",
        illustration: "",
      });
    }
    closeAllDropdown();
  };

  const handleRemove = async (id: string) => {
    setRemoveModal({
      ...removeModal,
      visible: true,
      id: id,
    });
    closeAllDropdown();
  };

  const handleRemoveConfirm = async (type: string) => {
    if (type === "confirm") {
      try {
        await api.deleteFirstScreen({ id: removeModal.id });
        getData();
      } catch (error: any) {
        message.error(error.message);
      }
    }
    setRemoveModal({
      ...removeModal,
      visible: false,
    });
  };

  const handleClose = () => {
    setModal({
      ...modal,
      visible: false,
    });
    getData();
  };

  useEffect(() => {
    getData();
  }, [reqParams]);

  return (
    <div className="sos-wrp">
      <div className="search-wrp clear">
        <div className="lft-block">
          <LookFor placeholder="Search..." keyChange={handleSearch} />
        </div>
        <div className="rgt-block">
          <Button
            className="create-button"
            onClick={() => handleCreateNewOrEdit("Create", "")}
          >
            Create new
          </Button>
        </div>
      </div>
      <Table
        headList={headList}
        class="illustrate-check"
        loading={loading}
        total={total}
        initTotal={null}
        keyword={reqParams.keyword}
        slot={
          <>
            {data.map((item: any, idx: number) => {
              return (
                <div
                  className="grid-line illustrate-first-screen"
                  key={item.firstScreenId}
                >
                  {/* image */}
                  <div className="label-th">
                    <div
                      className="image"
                      style={{ backgroundImage: `url(${item.illustration})` }}
                    ></div>
                  </div>
                  {/* title */}
                  <div className="label-th">
                    <span>{item.title}</span>
                  </div>
                  {/* action */}
                  <div className="label-th">
                    <Dropdown
                      trigger={["click"]}
                      open={item.open}
                      dropdownRender={() => (
                        <div className="dropdown-items shorter">
                          <div
                            className="dropdown-item"
                            onClick={() => {
                              handleCreateNewOrEdit("Edit", item.firstScreenId);
                            }}
                          >
                            Edit
                          </div>
                          <div
                            className="dropdown-item warn"
                            onClick={() => {
                              handleRemove(item.firstScreenId);
                            }}
                          >
                            Remove
                          </div>
                        </div>
                      )}
                    >
                      <div
                        className={item.open ? "dots active" : "dots"}
                        onClick={() => handleOpenDropdown(idx)}
                      ></div>
                    </Dropdown>
                  </div>
                </div>
              );
            })}
          </>
        }
      />
      {/* pagination */}
      <PageJump
        current={reqParams.pageNum}
        total={total}
        emitPageChange={(p: any) =>
          setReqParams({
            ...reqParams,
            pageNum: p,
          })
        }
      />

      {/* Modal  { show: false, type: "", title: "", id: "", loading: false }*/}
      <SimpleDialogCuztomized
        data={{
          show: removeModal.visible,
          title: "Are you sure you want to remove this illustration?",
          loading: removeModal.loading,
        }}
        width={560}
        emitEvent={handleRemoveConfirm}
      />
      <CreateNewModal
        visible={modal.visible}
        type={modal.type}
        editItem={editItem}
        onClose={handleClose}
      />
    </div>
  );
};

export default IllustrateFirstScreen;
