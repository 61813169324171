import "./lookFor.scss";
import LookForHook from "./lookForHook";

const LookFor = (props: any) => {
  const { Input, text, textChange } = LookForHook(props);

  return (
    <Input
      className="look-for-input"
      placeholder={props.placeholder}
      value={text}
      onChange={(e) => {
        textChange(e.target.value);
      }}
    />
  );
};

export { LookFor };
