import { Modal } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const ComplicatedDialogHook = (props: any) => {
  return {
    Modal,
    LoadingOutlined,
  };
};
export default ComplicatedDialogHook;
