import { useState, useEffect } from "react";
import "./doubleDates.scss";
import DoubleDatesHook from "./doubleDatesHook";

const DoubleDates = (props: any) => {
  const { RangePicker, disabled, getRangeTime } = DoubleDatesHook(props);
  return (
    <RangePicker
      className="dates-range"
      onChange={(e, s) => {
        getRangeTime(e, s);
      }}
      placeholder={["dd/mm/yyyy", "dd/mm/yyyy"]}
      format="DD/MM/YYYY"
      disabledDate={disabled}
    />
  );
};

export { DoubleDates };
