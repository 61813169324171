import { Modal, Button } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const SimpleDialogHook = (props: any) => {
  return {
    Modal,
    Button,
    LoadingOutlined,
  };
};
export default SimpleDialogHook;
