import { DatePicker } from "antd";
import { getDayTimes } from "../../../format/index";
const { RangePicker } = DatePicker;

const DoubleDatesHook = (props: any) => {
  const disabled = (current: any) => {
    // Can not select days before today and today
    return current && current > getDayTimes(1).start + 86400000;
  };

  const getRangeTime = (e: any, arr: any) => {
    if (!e) {
      props.dateChange({
        start: "",
        end: "",
      });
    } else {
      props.dateChange({
        start: arr[0],
        end: arr[1],
      });
    }
  };

  return { RangePicker, disabled, getRangeTime };
};
export default DoubleDatesHook;
