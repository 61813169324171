import axios from "../request/index";
import JSEncrypt from "jsencrypt";

const api = {
  getKeyApi({ callback = (() => {}) as any }) {
    axios
      .get(`/v1/public/rsa/public-key`)
      .then((res: any) => {
        callback(res);
      })
      .catch((err: any) => {
        callback(err);
      });
  },
  loginApi({ params = {} as any, callback = (() => {}) as any }) {
    /*const NodeRSA = require("node-rsa");
    const key = new NodeRSA(
      `-----BEGIN PUBLIC KEY-----\n${params.firebaseToken}\n-----END PUBLIC KEY-----`
    );
    const text = params.password;
    const encrypted = key.encrypt(text, "base64");
    params.password = encrypted;*/
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(params.key);
    const encrypted = encrypt.encrypt(params.password);

    axios
      .post(`/login`, {
        email: params.email,
        password: encrypted,
      })
      .then((res: any) => {
        callback(res);
      })
      .catch((err: any) => {
        callback(err);
      });
  },
  checkTokenApi({ callback = (() => {}) as any }) {
    axios
      .get(`/user/list?pageNum=1&pageSize=10`)
      .then((res: any) => {
        callback(res);
      })
      .catch((err: any) => {
        callback(err);
      });
  },
  logoutApi({ callback = (() => {}) as any }) {
    axios
      .post(`/logout`)
      .then((res: any) => {
        callback(res);
      })
      .catch((err: any) => {
        callback(err);
      });
  },
  uploadApi({ params = {} as any, callback = (() => {}) as any }) {
    axios
      .post(`/upload/file`, params)
      .then((res: any) => {
        callback(res);
      })
      .catch((err: any) => {
        callback(err);
      });
  },
  sendCodeApi({ params = {} as any, callback = (() => {}) as any }) {
    axios
      .post(`/sendEmailCaptcha`, params)
      .then((res: any) => {
        callback(res);
      })
      .catch((err: any) => {
        callback(err);
      });
  },
  verifyCodeApi({ params = {} as any, callback = (() => {}) as any }) {
    axios
      .post(`/verifyEmailCaptcha`, params)
      .then((res: any) => {
        callback(res);
      })
      .catch((err: any) => {
        callback(err);
      });
  },
  updatePsdApi({ params = {} as any, callback = (() => {}) as any }) {
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(params.key);
    const encrypted = encrypt.encrypt(params.newPassword);

    axios
      .post(`/resetPassword`, {
        email: params.email,
        emailCode: params.emailCode,
        newPassword: encrypted,
        // original: params.newPassword,
      })
      .then((res: any) => {
        callback(res);
      })
      .catch((err: any) => {
        callback(err);
      });
  },
};

export default api;
