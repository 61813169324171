import { Input } from "antd";
import { useState } from "react";

const LookForHook = (props: any) => {
  // 防抖节流用的定时器，点完2秒内无继续点，才调接口
  const [timeNum, setTimeNum] = useState(null) as any;

  // 防抖节流
  const debounce = (fn: any, key: string) => {
    clearTimeout(timeNum);

    let timeout = setTimeout(() => {
      fn(key);
    }, 2000);

    setTimeNum(timeout);
  };

  const tranferDatumToParent = (key: string) => {
    props.keyChange(key);
  };
  const [text, setText] = useState("");

  const textChange = (key: string) => {
    setText(key);
    // 输入后2秒没有继续输，就传出去
    debounce(tranferDatumToParent, key);
  };

  return { Input, text, textChange };
};
export default LookForHook;
