import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import Input, { InputRef } from "antd/es/input/Input";
import { cloneDeep, debounce, set } from "lodash";
import { Button, Dropdown, message, Form } from "antd";
import { PageJump, SimpleDialogCuztomized, Table } from "../../sharedModule";
import CreateNewModal from "./components/CreateNewModal";
import api from "../../../api/reminder";

interface ITab {
  label: string;
  tips: string;
  types: 1 | 2 | 3;
}
const tabs: ITab[] = [
  {
    label: "Pool A",
    tips: "At 7.30am each day, a reminder is sent if the user has not set a goal.",
    types: 1,
  },
  {
    label: "Pool C",
    tips: "When the user creates a goal, the goal is not completed.",
    types: 2,
  },
  {
    label: "Pool D",
    tips: "When the user creates a goal, it is not completed for a long time.",
    types: 3,
  },
];

const headList = [{ label: "Reminder Content", id: "1" }];

const GoalReminder = () => {
  const [active, setActive] = useState<ITab>(tabs[0]);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);
  const [reqParams, setReqParams] = useState<{
    pageNum: number;
    keyword: string;
    pageSize?: number;
    types?: number;
  }>({ pageNum: 1, pageSize: 10, keyword: "", types: 1 });

  const [total, setTotal] = useState<number>(0);
  const [editItem, setEditItem] = useState<any>({
    goalReminderId: "",
    goalReminder: "",
    types: 1,
  });

  const getData = async () => {
    try {
      setLoading(true);
      const res = await api.getReminderList({ params: reqParams });
      const data = res.data.records.map((i: any) => ({ ...i, open: false }));
      setData(data);
      setTotal(Number(res.data.total));
    } catch (error: any) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const [formRef] = Form.useForm();

  const tabClick = (label: string) => {
    const tab = tabs.find((item) => item.label === label);
    if (tab) {
      setActive(tab);
      setReqParams((pre) => ({
        ...pre,
        types: tab.types,
        keyword: "",
        pageNum: 1,
      }));
      setEditItem({
        goalReminderId: "",
        goalReminder: "",
        types: tab.types,
      });
      formRef.setFieldsValue({ keyword: "" });
    }
  };

  useEffect(() => {
    getData();
  }, [reqParams]);

  const handleSearch = useCallback(
    debounce((e: any) => {
      setReqParams((pre) => ({
        ...pre,
        keyword: e.target.value,
      }));
    }, 500),
    []
  );

  const [modal, setModal] = useState({
    visible: false,
    type: "Create",
  });
  const [removeModal, setRemoveModal] = useState({
    visible: false,
    loading: false,
    id: "",
  });

  const handleClose = () => {
    setModal({
      ...modal,
      visible: false,
    });
    getData();
    closeAllDropdown();
  };

  const handleOpenDropdown = (idx: number) => {
    const temp = data.map((item, index) => {
      if (index === idx) {
        item.open = !item.open;
      } else {
        item.open = false;
      }
      return item;
    });
    setData(temp);
  };

  const closeAllDropdown = () => {
    const list = cloneDeep(data);
    const temp = list.map((item) => {
      item.open = false;
      return item;
    });
    setData(temp);
  };

  const handleCreateNewOrEdit = (type: string, id: string) => {
    setModal({
      visible: true,
      type: type,
    });
    if (id) {
      const editItem = data.find((item) => item.goalReminderId === id);

      setEditItem(editItem);
    } else {
      setEditItem({
        goalReminderId: "",
        goalReminder: "",
        types: active.types,
      });
    }
    closeAllDropdown();
  };

  const handleRemove = async (id: string) => {
    setRemoveModal({
      visible: true,
      loading: false,
      id: id,
    });
    closeAllDropdown();
  };
  const handleRemoveConfirm = async (type: string) => {
    if (type === "confirm") {
      try {
        await api.deleteReminder({ id: removeModal.id });
        getData();
      } catch (error: any) {
        message.error(error.message);
      }
    }
    setRemoveModal({
      ...removeModal,
      visible: false,
    });
  };

  return (
    <div className="goalReminder-wrp">
      <h1>Goal Reminder</h1>
      <div className="tabs clear">
        {tabs.map((item: any) => {
          return (
            <div
              key={item.label}
              className={item.label === active.label ? "tab active" : "tab"}
              onClick={() => {
                tabClick(item.label);
              }}
            >
              {item.label}
            </div>
          );
        })}
      </div>
      <div className="search">
        <Form form={formRef}>
          <Form.Item name="keyword">
            <Input
              className="look-for-input"
              placeholder="Search..."
              onChange={(e) => handleSearch(e)}
            />
          </Form.Item>
        </Form>

        <Button
          className="create-button"
          onClick={() => handleCreateNewOrEdit("Create", "")}
        >
          Create new
        </Button>
      </div>
      {/*  */}
      <div className="tips">{active.tips}</div>
      {/* Table */}
      <Table
        headList={headList}
        class="gold-reminder"
        loading={loading}
        total={total}
        initTotal={null}
        keyword={reqParams.keyword}
        slot={data.map((item: any, idx: number) => {
          return (
            <div className="grid-line gold-reminder" key={item.goalReminderId}>
              {/* title */}
              <div className="label-th ">
                <div className="gold-reminder-content-text">
                  {item.goalReminder}
                </div>
              </div>
              {/* action */}
              <div className="label-th">
                <Dropdown
                  trigger={["click"]}
                  open={item.open}
                  destroyPopupOnHide
                  dropdownRender={() => (
                    <div className="dropdown-items shorter">
                      <div
                        className="dropdown-item"
                        onClick={() => {
                          handleCreateNewOrEdit("Edit", item.goalReminderId);
                        }}
                      >
                        Edit
                      </div>
                      <div
                        className="dropdown-item warn"
                        onClick={() => {
                          handleRemove(item.goalReminderId);
                        }}
                      >
                        Remove
                      </div>
                    </div>
                  )}
                >
                  <div
                    className={item.open ? "dots active" : "dots"}
                    onClick={() => handleOpenDropdown(idx)}
                  ></div>
                </Dropdown>
              </div>
            </div>
          );
        })}
      />
      <PageJump
        current={reqParams.pageNum}
        total={total}
        emitPageChange={(p: any) =>
          setReqParams({
            ...reqParams,
            pageNum: p,
          })
        }
      />

      <SimpleDialogCuztomized
        width={560}
        data={{
          show: removeModal.visible,
          title: " Are you sure you want to remove this reminder?",
          loading: removeModal.loading,
        }}
        emitEvent={handleRemoveConfirm}
      />
      <CreateNewModal
        visible={modal.visible}
        title={modal.type}
        editItem={editItem}
        onClose={handleClose}
      />
    </div>
  );
};

export default GoalReminder;
